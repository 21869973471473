import {Injectable} from '@angular/core';
import CustomerPortfolioStore from 'src/app/domain/store/customer-portfolio.store';
import {CustomerPortfolio, isCanceledPortfolio} from 'src/app/domain/model/customer-portfolio.model';
import {distinctUntilChanged, Observable} from 'rxjs';
import {isEmpty, isEqual, toArray} from 'lodash';
import {filter, tap} from 'rxjs/operators';
import {objKeysToSnakeCase} from 'src/app/domain/function/object.helper';
import PortfolioSnapshotStore from 'src/app/domain/store/portfolio-snapshot.store';
import {ApiClient} from 'src/app/infrastructure/http/api.client';
import {UpdateCustomerPortfolioTitleCommand} from 'src/app/domain/command/customer-portfolio.command';

@Injectable({
  providedIn: 'root',
})
export default class CustomerPortfolioApi {
  private readonly url = 'portfolio';

  constructor(
    private http: ApiClient,
    private portfolioStore: CustomerPortfolioStore,
    private readonly snapshotStore: PortfolioSnapshotStore,
  ) {
  }

  public async fetchAll(): Promise<void> {
    const data: CustomerPortfolio[] = toArray(await this.http.get(this.url));
    this.portfolioStore.saveAll(data);
    this.updateSelectedPortfolio(data);
  }

  public selected(): Observable<CustomerPortfolio> {
    return this.portfolioStore.selected$;
  }

  public syncSelected(): CustomerPortfolio | null {
    return this.portfolioStore.syncSelected();
  }

  public all(): Observable<CustomerPortfolio[]> {
    return this.portfolioStore.portfolios$.pipe(
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      tap(stored => {
        if (isEmpty(stored)) {
          this.fetchAll();
        }
      }),
      filter(stored => !!stored)
    );
  }

  public syncAll(): CustomerPortfolio[] {
    return this.portfolioStore.syncAll();
  }

  public selectPortfolio(portfolio: CustomerPortfolio): void {
    this.portfolioStore.saveSelected(portfolio);
    this.snapshotStore.reset();
  }

  public async patchCustomerPortfolioTitle(command: UpdateCustomerPortfolioTitleCommand): Promise<void> {
    const snakeCommand: any = objKeysToSnakeCase(command);
    const queryParams = {
      title: snakeCommand.title,
    };
    await this.http.patch(
        this.url + '/' + snakeCommand.portfolio_id,
        queryParams
    );
  }

  private updateSelectedPortfolio(portfolios: CustomerPortfolio[]): void {
    if (Object.keys(portfolios).length === 1) {
      this.portfolioStore.saveSelected(portfolios[0]);
      return;
    }

    let selectedPortfolio: CustomerPortfolio = this.portfolioStore.syncSelected();
    if (selectedPortfolio === null) {
      selectedPortfolio = portfolios.find((portfolio) => !isCanceledPortfolio(portfolio)) ?? portfolios[0];
      this.portfolioStore.saveSelected(selectedPortfolio);
      return;
    }

    portfolios.forEach((portfolio: CustomerPortfolio) => {
      if (portfolio.id === selectedPortfolio.id) {
        this.portfolioStore.saveSelected(portfolio);
      }
    });
  }
}
